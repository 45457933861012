import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Hero from "components/Hero";
import Layout from "components/Layout";

export const ErrorPageTemplate = ({ page }) => {
  return (
    <>
      <Hero py={["100px", "200px"]} shade="light" content={page.hero} />
    </>
  );
};

const ErrorPage = ({ data }) => {
  const { frontmatter: page } = data.allMarkdownRemark.edges[0].node;

  return (
    <Layout shade="light" headerShade="light" content={page}>
      <ErrorPageTemplate page={page} />
    </Layout>
  );
};

ErrorPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ErrorPage;

export const errorPageQuery = graphql`
  query ErrorPage {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "error-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            page {
              title
            }
            hero {
              heading
              subheading
              buttonPath
              buttonText
            }
          }
        }
      }
    }
  }
`;
